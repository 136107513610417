"use strict";

const { Logger } = require("sass");

window.addEventListener("DOMContentLoaded", () => {
  const modal = () => {
    // Функция для открытия модалки и отключения прокрутки
    function openModal(modal) {
      if (modal) {
        document.body.style.paddingRight = `10px`; // Устанавливаем отступ
        modal.classList.add("show");
        document.body.classList.add("no-scroll"); // Отключаем прокрутку
      }
    }

    // Функция для закрытия модалки и включения прокрутки
    function closeModal(modal) {
      if (modal) {
        document.body.style.paddingRight = ""; // Убираем отступ
        modal.classList.remove("show");
        document.body.classList.remove("no-scroll"); // Включаем прокрутку
      }
    }

    // Открытие модалки по нажатию кнопки с data-modal-target
    document.addEventListener("click", (event) => {
      const modalTarget = event.target.getAttribute("data-modal-target");
      if (modalTarget) {
        const modal = document.querySelector(modalTarget);
        openModal(modal);
      }
    });

    // Закрытие модалки по нажатию на кнопку закрытия или вне контента
    document.addEventListener("click", (event) => {
      // Закрытие модалки по кнопке с атрибутом data-close-button
      if (event.target.hasAttribute("data-close-button")) {
        const modal = event.target.closest(".modal");
        closeModal(modal);
      }

      // Закрытие модалки при клике вне основного контента с data-modal-content
      const modal = event.target.closest(".modal");
      const isContent = event.target.closest("[data-modal-content]");
      if (modal && !isContent) {
        closeModal(modal);
      }
    });

    {
      /* 
      data-modal-target="#modal-auth"  --trigger
    
      <div id="modal-auth" class="modal modal-auth">
        <div class="modal__wrapper">
          <div data-modal-content class="modal__content modal-auth__content">
            <button
              class="modal__close"
              data-close-button
              aria-label="Close modal window"
            ></button>
            
          </div>
        </div>
      </div> 
      */
    }
  };
  modal();

  const createOrderBuyWrap = (imgSrc, name, price, discount) => {
    // Создаем обертку
    const orderBuyWrap = document.createElement("div");
    orderBuyWrap.classList.add("order-buy__wrap");

    orderBuyWrap.setAttribute("data-discount-order-value", discount);

    // Создаем блок с изображением
    const imgWrap = document.createElement("div");
    imgWrap.classList.add("order-buy__wrap-img");
    const picture = document.createElement("picture");
    const img = document.createElement("img");
    img.src = imgSrc;
    img.width = 60;
    img.height = 60;
    img.alt = "kit book";
    img.loading = "lazy";
    img.setAttribute("aria-hidden", "true");
    picture.appendChild(img);
    imgWrap.appendChild(picture);

    const kitWrap = document.createElement("div");

    // Создаем текстовый блок
    const textWrap = document.createElement("div");
    textWrap.classList.add("order-buy__wrap-text");
    kitWrap.textContent = "Комплект ";

    // Создаем строку с комплектом
    kitWrap.classList.add("order-buy__wrap-kit");
    const importantText = document.createElement("span");
    importantText.classList.add("order-buy__wrap-important");
    importantText.textContent = name;
    kitWrap.appendChild(importantText);

    // Создаем описание
    const desc = document.createElement("div");
    desc.classList.add("order-buy__wrap-desc");
    desc.textContent = price;

    textWrap.appendChild(kitWrap);
    textWrap.appendChild(desc);

    // Создаем кнопку удаления
    const deleteBtn = document.createElement("button");
    deleteBtn.classList.add("order-buy__wrap-delete");
    deleteBtn.classList.add("d-none");
    deleteBtn.textContent = "✖";

    // Собираем структуру
    orderBuyWrap.appendChild(imgWrap);
    orderBuyWrap.appendChild(textWrap);
    orderBuyWrap.appendChild(deleteBtn);

    return orderBuyWrap;
  };

  const deleteValidation = () => {
    let maxDiscountValue = [];
    const allWraps = document.querySelectorAll("[data-discount-order-value]");
    if (allWraps.length) {
      allWraps.forEach((wrap) => {
        const deleteBtn = wrap.querySelector(".order-buy__wrap-delete");
        deleteBtn.classList.add("d-none");
        const discountValue = parseInt(
          wrap.getAttribute("data-discount-order-value"),
          10
        );
        maxDiscountValue.push(discountValue);
      });
      let maxValue = Math.max(...maxDiscountValue);
      allWraps.forEach((wrap) => {
        const discountValue = parseInt(
          wrap.getAttribute("data-discount-order-value"),
          10
        );
        if (maxValue == discountValue) {
          const deleteBtn = wrap.querySelector(".order-buy__wrap-delete");
          deleteBtn.classList.remove("d-none");
        }
      });
    }
  };

  const addBtnsHandler = (
    addBtns,
    discountItem,
    nextDiscountItem,
    discount
  ) => {
    const dataOrderList = document.querySelector("[data-order-list]");

    addBtns.forEach((addBtn) => {
      addBtn.addEventListener("click", () => {
        const parent = addBtn.closest(".order__item");
        const imgSrc = parent.querySelector("picture img").src;
        const name = parent.querySelector(".order__item-title").textContent;
        const price = parent.querySelector(".order__item-subtitle").textContent;

        const dataOrderListItem = createOrderBuyWrap(
          imgSrc,
          name,
          price,
          discount
        );
        dataOrderList.appendChild(dataOrderListItem);
        costHandler();
        deleteValidation();

        discountItem.classList.add("d-none");

        if (nextDiscountItem) {
          nextDiscountItem.classList.remove("d-none");
        }

        deleteBtnHandler(discountItem, nextDiscountItem, discount);

        if (discount === "40") {
          const orderList = document.querySelector(".order__big");
          orderList.classList.add("d-none");

          const orderForm = document.querySelector(".order__small");
          orderForm.style.margin = "0 auto 0";
        }
      });
    });
  };

  const deleteBtnHandler = (discountItem, nextDiscountItem, discount) => {
    const deleteBtns = document.querySelectorAll(".order-buy__wrap-delete");

    deleteBtns.forEach((deleteBtnItem) => {
      deleteBtnItem.addEventListener("click", () => {
        const parent = deleteBtnItem.closest(".order-buy__wrap");
        const value = parent.getAttribute("data-discount-order-value");

        if (value == discount) {
          discountItem.classList.remove("d-none");
          if (nextDiscountItem) {
            nextDiscountItem.classList.add("d-none");
          }
        }

        if (discount == 40) {
          const orderList = document.querySelector(".order__big");
          orderList.classList.remove("d-none");

          const orderForm = document.querySelector(".order__small");
          orderForm.style.margin = "none";
        }

        parent.remove();
        costHandler();
        deleteValidation();
      });
    });
  };

  const costHandler = () => {
    const costSelector = document.querySelector("[data-order-price]");
    const allItems = document.querySelectorAll("[data-discount-order-value]");
    let allPrices = [];
    let sum = 0;

    allItems.forEach((item) => {
      const value = item.querySelector(".order-buy__wrap-desc").textContent;
      const price = parseInt(value, 10);
      allPrices.push(price);
    });

    allPrices.forEach((num) => {
      sum += num;
    });

    costSelector.textContent = sum + " грн";
  };

  const orderHandler = () => {
    const discountList = document.querySelectorAll("[data-discount-list]");

    if (discountList.length) {
      discountList.forEach((discountItem, index) => {
        const discountItemValue =
          discountItem.getAttribute("data-discount-list");
        const addBtns = discountItem.querySelectorAll(
          "[data-discount-list-btn]"
        );
        const nextDiscountItem = discountList[index + 1];

        switch (discountItemValue) {
          case "10":
            addBtnsHandler(addBtns, discountItem, nextDiscountItem, "10");
            break;
          case "20":
            addBtnsHandler(addBtns, discountItem, nextDiscountItem, "20");
            break;
          case "30":
            addBtnsHandler(addBtns, discountItem, nextDiscountItem, "30");
            break;
          case "40":
            addBtnsHandler(addBtns, discountItem, nextDiscountItem, "40");

            break;
        }
      });
    }
  };
  orderHandler();
});
